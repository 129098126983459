<template>
  <div class="menu-details">
    <employeepicture :pictureUrl="pictureUrl" />
    <div class="entry divider" style="margin-bottom: 30px; text-align: center">
      <div class="line-through"></div>
      <labelc type="header no-break" :text="name" />
      <div class="line-through"></div>
    </div>
    <div v-if="showApps">
      <labelc
        v-for="i in computedIconsMain"
        v-bind:key="i.icon"
        type="block border-bottom"
        :text="i.label"
        @click="navigateExternal(i.externalLink)"
        :icon-left="{ icon: i.icon }"
      />
      <labelc
        type="block border-bottom"
        text="zurück"
        @click="showApps = false"
        :icon-left="{ icon: 'fa-chevron-left' }"
      />
    </div>
    <div v-else>
      <!--
            <labelc v-if="News != null" type="block border-bottom border-top" text="Nachrichten" @click="News(), Close()" :icon-left="{icon: 'fa-bell'}" :icon-right="eventNumber > 0 ? {icon: 'fa-circle', label:eventNumber, type: 'red'} : null"/>
            <labelc v-if="EventLog != null" type="block border-bottom border-top" text="Ereignisse" @click="EventLog(), Close()" :icon-left="{icon: 'fa-list-ul'}" />-->

      <!--<labelc type="block border-bottom" text="Einstellungen" @click="Settings(), Close()" :icon-left="{icon: 'fa-cog'}"/>-->
      <labelc
        type="block border-bottom"
        text="Mein Account"
        @click="MyAccount(), Close()"
        :icon-left="{ icon: 'fa-user-cog' }"
      />
      <labelc
        type="block border-bottom"
        text="Weitere Anwendungen"
        @click="showApps = true"
        :icon-left="{ icon: 'fa-tablet-alt' }"
        :icon-right="{ icon: 'fa-chevron-right' }"
      />
      <!--<labelc type="block border-bottom" text="Hilfe" @click="Help(), Close()" :icon-left="{icon: 'fa-question-circle'}"/>-->
      <labelc
        type="block border-bottom"
        text="Abmelden"
        @click="SignOut(), Close()"
        :icon-left="{ icon: 'fa-sign-out-alt' }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "menudetails",
  data() {
    return {
      showApps: false,
      pictureUrl: "",
      name: "",
      News: () => {},
      Settings: () => {},
      MyAccount: () => {},
      EventLog: () => {},
      Help: () => {},
      SignOut: () => {},
      eventNumber: 0,
    };
  },
  created() {
    if (this.$parent.show) this.$parent.show = false;
  },
  mounted() {},
  computed: {
    computedIconsMain() {
      let sub = this.getSubDomain();
      let urls = [];
      if (sub === "staging") {
        urls = [
          "https://account.meindienstplan.at/#/?redirect=devmdpl",
          "https://account.meindienstplan.at/#/?redirect=stagingmzlogin",
          "https://account.meindienstplan.at/#/?redirect=stagingmplogin",
          "https://account.meindienstplan.at/#/?redirect=stagingmdcorelogin",
          "http://www.meindienstplan.at/hilfe",
        ];
      } else if (sub === "development") {
        urls = [
          "https://account.meindienstplan.at/#/?redirect=devmdpl",
          "https://account.meindienstplan.at/#/?redirect=stagingmzlogin",
          "https://account.meindienstplan.at/#/?redirect=stagingmplogin",
          "https://account.meindienstplan.at/#/?redirect=stagingmdcorelogin",
          "http://www.meindienstplan.at/hilfe",
        ];
      } else {
        urls = [
          "https://account.meindienstplan.at/#/?redirect=mdpl",
          "https://account.meindienstplan.at/#/?redirect=mzlogin",
          "https://account.meindienstplan.at/#/?redirect=mplogin",
          "https://account.meindienstplan.at/#/?redirect=mdcorelogin",
          "http://www.meindienstplan.at/hilfe",
        ];
      }

      let iconsMain = [
        {
          icon: "icon-main-md",
          label: "Mein Dienstplan",
          externalLink: urls[0],
        },
        {
          icon: "icon-main-mz",
          label: "Meine Zeiterfassung",
          externalLink: urls[1],
        },
        { icon: "icon-main-mp", label: "Mein Player", externalLink: urls[2] },
        { icon: "fa-user", label: "Mein Kundencenter", externalLink: urls[3] },
        {
          icon: "fa-laptop-medical",
          label: "Remote Support",
          externalLink: urls[4],
        },
      ];

      return iconsMain;
    },
  },
  watch: {
    state: {
      immediate: true,
      handler(newVal, oldVal) {
        for (let x in newVal) this[x] = newVal[x];
      },
    },
  },
  props: {
    state: Object,
  },
  methods: {
    Close() {
      this.$parent.Toggle();
    },
    getSubDomain: function () {
      let full = window.location.host;
      //window.location.host is subdomain.domain.com
      let parts = full.split(".");
      return parts[0];
    },
    navigateExternal(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.menu-details {
  width: 250px;
}

.menu-details .picture {
  display: block;
  position: relative;
  margin: auto;
}

.icon-main-mz,
.icon-main-mp,
.icon-main-md {
  background: url(/logo.png);
  background-size: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}
</style>
